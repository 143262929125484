async function loadConfig() {
  try {
    const response = await fetch("/config/config.json")
    const config = await response.json()
    window.env = {
      API_URL: config.VITE_API_URL,
      UI_URL: config.VITE_UI_URL,
      API_VERSION: config.VITE_API_VERSION,
      AUTH_URL: config.VITE_AUTH_URL,
      AUTH_CLIENT_ID: config.VITE_AUTH_CLIENT_ID,
      AUTH_REDIRECT_URI: config.VITE_AUTH_REDIRECT_URI,
      LOGOUT_REDIRECT_URI: config.VITE_LOGOUT_REDIRECT_URI,
      AUTH_SCOPE: config.VITE_AUTH_SCOPE,
      MAINTENANCE_MODE: config.MAINTENANCE_MODE,
      PLAUSIBLE_ENV: config.PLAUSIBLE_ENV,
    }
  } catch (error) {
    console.error("Failed to load configuration:", error)
  }
}

loadConfig().then(() => {
  import("./main")
})
